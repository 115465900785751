<template>
  <a-select :value="localValue" @change="onChange" @blur="onBlur" v-bind="bindPropsAttrs">
    <template v-if="loading" #notFoundContent>
      <a-spin size="small" />
    </template>
    <a-select-option v-if="appendOption.label" :key="appendOption.value">{{ appendOption.label }}</a-select-option>
    <a-select-option v-for="(option, index) of normalizedOptions" :key="option.value">
      <slot v-if="slots.customOption" name="customOption" :option="options[index]"></slot>
      <span v-else>{{ option.label }}</span>
    </a-select-option>
  </a-select>
</template>
<script lang="ts">
import { defineComponent, ref, PropType, computed, reactive } from 'vue'
import { isFunction, isString, isObject, isNumber, get, debounce, find } from 'lodash-es'

import { Select as ASelect, SelectOption as ASelectOption, Spin as ASpin } from 'ant-design-vue'
import { useAjax } from '@vue-mfe/utils'

interface BasicSelectAjax {
  get: {
    action: string
    params: (sth: string) => object
  }
}

interface BasicSelectProps {
  label: string
  value: string
}

const normalizeOptionProps = (option: object, rawProps: any, error?: string) => {
  let normalizedProps = rawProps
  if (isString(rawProps)) normalizedProps = get(option, rawProps, error)
  else if (isFunction(rawProps)) normalizedProps = rawProps(option)
  return normalizedProps
}

// const getValueWrapper = (value: any, expression: string) => {
//   if (typeof expression === 'string') {
//     let res = value
//     expression
//       .split('.')
//       .reverse()
//       .forEach(
//         (key) => {
//           res = {
//             [key]: res
//           }
//         }
//       )
//     return res
//   }
//   return false
// }

export default defineComponent(
  {
    components: {
      ASelect,
      ASelectOption,
      ASpin
    },

    props: {
      modelValue: {
        type: null
      },
      ajax: {
        type: Object as PropType<BasicSelectAjax>
      },
      remote: {
        type: Boolean as PropType<boolean>
      },
      allowCreate: {
        type: Boolean as PropType<boolean>
      },
      props: {
        type: Object as PropType<BasicSelectProps>,
        required: true
      },
      valueKey: {
        type: String as PropType<string>,
        default: ''
      }
    },

    emits: ['update:modelValue', 'change'],

    setup (props, { emit, slots }) {
      const appendOption = reactive<{ label: string; value: 'x' }>(
        {
          label: '',
          value: 'x'
        }
      )
      const options = ref<any[]>([])
      const loading = ref(false)

      const localValue = computed(
        () => {
          let value
          if (!props.props.value && props.valueKey && isObject(props.modelValue)) {
            value = get(props.modelValue, props.valueKey)
          }
          if (props.props.value && (isString(props.modelValue) || isNumber(isString(props.modelValue)))) {
            value = props.modelValue
          }
          return value || undefined
        }
      )

      const onChange = (value: any) => {
        if (value === 'x') {
          emit('update:modelValue', appendOption.label)
        } else if (props.props.value) emit('update:modelValue', value)
        else if (!props.props.value && props.valueKey) {
          emit('update:modelValue', find(options.value, [props.valueKey, value]))
        }
        emit('change', { value, option: find(options.value, [props.props.value || props.valueKey, value]) })
      }

      const onBlur = () => {
        // onChange(appendOption)
      }

      const remoteMethod = (value: string) => {
        if (props.ajax && props.ajax.get) {
          const ajaxConfig: any = { ...props.ajax.get }
          ajaxConfig.lazy = true
          if (isFunction(ajaxConfig.params)) {
            ajaxConfig.params = ajaxConfig.params(value)
          }
          const requestInterface = () => {
            loading.value = true
            useAjax(ajaxConfig)
              .run()
              .then(
                (res: any) => {
                  const data = res.data.data

                  // const modelValue = props.modelValue
                  options.value = data
                  loading.value = false
                }
              )
          }
          requestInterface()
        }
      }

      remoteMethod('')

      const bindPropsAttrs = computed(
        () => {
          const propsAttrs: any = {}
          if (props.remote || props.allowCreate) {
            propsAttrs.showSearch = true
            propsAttrs.filterOption = false
            propsAttrs.onSearch = debounce(
              (input: string) => {
                remoteMethod(input)
                if (props.allowCreate) appendOption.label = input
              },
              800
            )
          }
          return propsAttrs
        }
      )

      const normalizedOptions = computed(
        () => {
          return options.value.map(
            (option) => {
              const optionProps: Partial<BasicSelectProps> = {}
              optionProps.label = normalizeOptionProps(option, props.props.label, 'error label')
              if (props.props.value) {
                optionProps.value = normalizeOptionProps(option, props.props.value, 'error value')
              } else if (!props.props.value && props.valueKey) {
                optionProps.value = normalizeOptionProps(option, props.valueKey, 'error value')
              }
              return optionProps
            }
          )
        }
      )

      return {
        localValue,
        loading,
        options,
        onChange,
        onBlur,
        bindPropsAttrs,
        normalizedOptions,
        appendOption,
        slots
      }
    }
  }
)
</script>
