<template>
  <div class="view-wrapper">
    <div class="view-wrapper__header">
      <!-- 面包屑 -->
      <div class="h-40px w-full flex items-center">
        <a-breadcrumb :routes="routes"></a-breadcrumb>
      </div>

      <div class="flex items-center justify-between py-5px">
        <span class="text-18px font-600">{{ title }}</span>
        <div class="view-wrapper__header-operation">
          <slot name="operation"></slot>
        </div>
      </div>

      <div>
        <slot name="header"></slot>
      </div>
    </div>

    <div class="view-wrapper__main">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { toLower } from 'lodash-es'
import { Breadcrumb as ABreadcrumb } from 'ant-design-vue'

import { useRoute } from 'vue-router'
import { supMenus, cliMenus } from '@vue-mfe/utils'
import type { Menu } from '@vue-mfe/utils'

interface Route {
  path: string
  breadcrumbName: string
  children?: Array<
    {
      path: string
      breadcrumbName: string
    }
  >
}

defineProps(
  {
    title: String as PropType<string>
  }
)

const routee = useRoute()
const routes = computed(
  () => {
    const routesRes: Route[] = []
    const role = localStorage.getItem('ENOQUOTE_ROLE')

    const menu2Route = (menu: Menu): Route => ({
      path: '',
      breadcrumbName: menu.message
    })

    const generatorRoutes = (menus: Menu[]) => {
      for (let menu of menus) {
        if (toLower(menu.id) === routee.name) {
          routesRes.push(menu2Route(menu))
        } else if (menu.children) {
          for (let child of menu.children) {
            if (toLower(child.id) === routee.name) {
              routesRes.push(menu2Route(menu))
              routesRes.push(menu2Route(child))
            }
          }
        }
      }
    }

    if (role && routee.name) {
      switch (role) {
        case 'SUPPLIER':
          generatorRoutes(supMenus)
          break
        case 'CLIENT':
          generatorRoutes(cliMenus)
          break
      }
    }

    return routesRes
  }
)
</script>

<style scoped>
.view-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.view-wrapper__header {
  padding-bottom: 0;
  background: #fff;
  /* padding: 0 20px;
  padding-top: 20px; */
  padding: 20px;
}

.view-wrapper__header-operation:deep(.ant-btn) {
  margin-left: 10px;
}

.view-wrapper__main {
  flex: 1 1 auto;
  overflow: auto;
  padding: 20px;
}

.view-wrapper__main::-webkit-scrollbar {
  background: transparent;
  width: 10px;
  height: 6px;
}

.view-wrapper__main::-webkit-scrollbar-corner {
  background: transparent;
}

.view-wrapper__main::-webkit-scrollbar-thumb {
  transition: background 0.2s ease-in-out;
  background: rgba(0, 0, 0, 0.2) !important;
  border-radius: 9999px;
}

.view-wrapper__main:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2) !important;
}
</style>
