<template>
  <a-drawer title="选择易损件" :visible="visible" @close="() => $emit('update:visible', false)" width="480">
    <div>
      <div style="margin: 5px 0">已选择</div>
      <div class="checked-list-box">
        <a-tag v-for="item in showParts" :key="item.code" closable @close="delePart(item)">{{ item.message }}</a-tag>
      </div>
    </div>
    <div>
      <div>
        <a-checkbox :checked="checkAll" @change="checkAllChange">全选 </a-checkbox>
      </div>
      <div class="check-list-box">
        <span class="check-list-box__item" style="width: 140px" v-for="check in state.partList" :key="check.code">
          <a-checkbox v-model:checked="check.checked" @change="checkChange(check)">{{ check.message }} </a-checkbox>
        </span>
      </div>
    </div>
    <div style="margin-top: 10px; margin-bottom: 8px">自定义添加</div>
    <a-row>
      <a-col :span="19">
        <a-input :maxlength="12" v-model:value="state.customPartName" placeholder="输入易损件名称，进行添加" />
      </a-col>
      <a-col :span="5">
        <a-button @click="addCustom">添加</a-button>
      </a-col>
    </a-row>
    <div class="drawer-footer">
      <a-button style="margin-right: 8px" @click="$emit('update:visible', false)">取消</a-button>
      <a-button type="primary" @click="confrim">确认</a-button>
    </div>
  </a-drawer>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, reactive, watch } from 'vue'
import { useAjax } from '@vue-mfe/utils'
import { cloneDeep } from 'lodash-es'
import {
  Col as ACol,
  Row as ARow,
  Form as AForm,
  Drawer as ADrawer,
  Radio as ARadio,
  RadioGroup as ARadioGroup,
  Tag as ATag,
  Checkbox as ACheckbox,
  message
} from 'ant-design-vue'

interface PratItem {
  code: string | number
  message: string
  checked: boolean
  key: string
}

export default defineComponent(
  {
    props: {
      visible: Boolean as PropType<boolean>,
      parts: {
        type: Array as PropType<any[]>,
        require: true,
        defalut: () => []
      },
      customParts: {
        type: Array as PropType<any[]>,
        require: true,
        defalut: () => []
      }
    },
    components: {
      ACol,
      ARow,
      AForm,
      ADrawer,
      ARadio,
      ARadioGroup,
      ATag,
      ACheckbox
    },
    emits: ['update:visible', 'update:parts', 'update:customParts'],
    setup (props, { emit }) {
      const partList = useAjax(
        {
          action: 'GET /common/lookup/:lookupType',
          params: 'SPLPT',
          lazy: true
        }
      )
      const state = reactive(
        {
          ininParts: [] as any[],
          initCustomParts: [] as any[],
          partList: [] as any[],
          cheackParts: [] as any[],
          checkAll: false,
          customPartName: ''
        }
      )
      partList.run().then(
        (res) => {
          state.partList = res.data.data
        }
      )
      const checkChange = (item: any) => {
        if (item.checked) {
          state.ininParts.push(item)
        } else {
          const index = state.ininParts.findIndex((inner: any) => inner.code === item.code)
          state.ininParts.splice(index, 1)
        }
      }
      const checkAllChange = () => {
        if (!checkAll.value) {
          state.ininParts = []
          state.partList.forEach(
            (item: any) => {
              item.checked = true
              state.ininParts.push(item)
            }
          )
        } else {
          state.ininParts = []
          state.partList.forEach(
            (item: any) => {
              item.checked = false
            }
          )
        }
      }
      const delePart = (item: any) => {
        if (item.key && item.key === 'custom') {
          const index = state.initCustomParts.findIndex((inner: any) => inner.keyword === item.message)
          state.initCustomParts.splice(index, 1)
        } else {
          const index = state.ininParts.findIndex((inner: any) => inner.code === item.code)
          state.ininParts.splice(index, 1)
          state.partList.find((inner: any) => inner.code === item.code).checked = false
        }
      }
      const addCustom = () => {
        if (!state.customPartName) return message.warning('请先输入易损件名称')
        const hasId = state.initCustomParts.map((item: any) => item.code)
        const getId = () => {
          const getRandomId = Math.ceil(Math.random() * 100)
          if (!hasId.includes(getRandomId)) return getRandomId
          else getId()
        }
        const part = {
          key: 'custom',
          code: getId(),
          keyword: state.customPartName
        }
        state.initCustomParts.push(part)
        state.customPartName = ''
      }
      const confrim = () => {
        emit('update:parts', state.ininParts)
        emit('update:customParts', state.initCustomParts)
        emit('update:visible', false)
      }
      const checkAll = computed(
        () => {
          return state.ininParts.length === state.partList.length
        }
      )
      const showParts = computed(
        () => {
          let res: PratItem[] = []
          res = state.ininParts?.map(
            (item: any) => {
              item.checked = true
              state.partList.find((inner: any) => item.code === inner.code).checked = true
              return item
            }
          )
          state.initCustomParts.forEach(
            (item: any) => {
              const part: PratItem = {
                code: item.id,
                message: item.keyword,
                checked: true,
                key: 'custom'
              }
              res.push(part)
            }
          )
          return res
        }
      )
      watch(
        () => props.visible,
        (value) => {
          if (value) {
            state.ininParts = cloneDeep(props.parts) || []
            state.initCustomParts = cloneDeep(props.customParts) || []
          } else {
            state.partList &&
              state.partList.forEach(
                (item: any) => {
                  item.checked = false
                }
              )
          }
        }
      )
      return {
        state,
        showParts,
        checkAll,
        delePart,
        checkAllChange,
        checkChange,
        addCustom,
        confrim
      }
    }
  }
)
</script>

<style scoped>
.checked-list-box :deep(.ant-tag) {
  min-width: 70px;
  padding: 8px 12px;
  margin-right: 6px;
  margin-bottom: 12px;
}
.check-list-box {
  display: flex;
  flex-wrap: wrap;
}
.check-list-box__item {
  width: 120px;
}
</style>
