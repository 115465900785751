<script lang="ts" setup>
import { shallowRef, onMounted, onUnmounted, PropType, watch, nextTick } from 'vue'
import * as Echarts from 'echarts/core'
import type { ComposeOption } from 'echarts/core'
import { BarChart, PieChart } from 'echarts/charts'
import type { BarSeriesOption, PieSeriesOption } from 'echarts/charts'
import { TitleComponent, GridComponent, TooltipComponent, LegendComponent, DatasetComponent } from 'echarts/components'

import type {
  TitleComponentOption,
  GridComponentOption,
  ToolboxComponentOption,
  LegendComponentOption
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'

type InitType = typeof Echarts.init
type EChartsType = ReturnType<InitType>
type ECOption = ComposeOption<
  | BarSeriesOption
  | PieSeriesOption
  | TitleComponentOption
  | GridComponentOption
  | ToolboxComponentOption
  | LegendComponentOption
>

const props = defineProps(
  {
    loading: Boolean as PropType<Boolean>,
    autoresize: Boolean as PropType<Boolean>,
    option: {
      type: Object as PropType<ECOption>,
      required: true
    }
  }
)

Echarts.use(
  [
    TitleComponent,
    GridComponent,
    TooltipComponent,
    LegendComponent,
    DatasetComponent,
    BarChart,
    PieChart,
    CanvasRenderer
  ]
)

const root = shallowRef<HTMLElement>()
const chart = shallowRef<EChartsType>()

watch(
  () => props.option,
  () => nextTick(setOption),
  {
    immediate: true,
    deep: true
  }
)

function init () {
  if (chart.value || !root.value) return
  const instance = (chart.value = Echarts.init(
    root.value,
    'light',
    {
      renderer: 'canvas'
    }
  ))

  function resize () {
    if (instance && !instance.isDisposed()) {
      try {
        instance.resize()
      } catch (e) {
        if (e.message === "Cannot read property 'get' of undefined") return
        throw e
      }
    }
  }

  if (props.autoresize) nextTick(resize)
}

function setOption () {
  if (!chart.value || !props.option) return
  chart.value.setOption(props.option)
}

function cleanup () {
  if (chart.value) {
    chart.value.dispose()
    chart.value = undefined
  }
}

onMounted(
  () => {
    if (props.option) init()
  }
)
onUnmounted(cleanup)
</script>

<template>
  <div ref="root" class="h-[300px] w-full"></div>
</template>
