<template>
  <a-drawer v-bind="$attrs" :bodyStyle="bodyStyle" :drawerStyle="drawerStyle">
    <div class="p-[20px]">
      <slot></slot>
    </div>

    <div class="e-drawer__footer" v-if="slots.footer">
      <slot name="footer"></slot>
    </div>
  </a-drawer>
</template>

<script lang="ts" setup>
import { computed, useSlots } from 'vue'
import { Drawer as ADrawer } from 'ant-design-vue'

const slots = useSlots()

const drawerStyle = {
  overflow: 'hidden'
}

const bodyStyle = computed(
  () => {
    const style = {
      height: 'calc(100vh - 55px)',
      padding: 0,
      overflow: 'auto'
    }
    if (slots.footer) style.height = 'calc(100vh - 55px - 40px)'
    return style
  }
)
</script>

<style scoped>
.e-drawer__footer {
  border-top: 1px solid #d9d9d9;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  background: rgba(0, 0, 0, 0.06);
}

.e-drawer__footer :deep(.ant-btn) {
  margin-left: 10px;
}
</style>
