<template>
  <a-cascader
    :value="modelValue"
    :options="options"
    placeholder="请选择"
    :field-names="{ label: 'name', value: 'code', children: 'children' }"
    :load-data="loadData"
    change-on-select
    @update:value="onUpdateValue"
  ></a-cascader>
</template>

<script lang="ts" setup>
import { onMounted, ref, PropType } from 'vue'
import { Cascader as ACascader } from 'ant-design-vue'

import { find } from 'lodash-es'

import { useAjax } from '@vue-mfe/utils'

interface Option {
  name: string
  code: string
  loading?: boolean
  isLeaf?: boolean
  children?: Option[]
}

const props = defineProps(
  {
    modelValue: Array as PropType<string[]>
  }
)

const emit = defineEmits(['update:modelValue', 'change'])

function getData (code?: string): Promise<Option[]> {
  return new Promise(
    (resolve, reject) => {
      useAjax<Option>(
        {
          action: 'GET /common/area/children',
          params: () => ({
            parentCode: code
          }),
          convert: {
            client (data) {
              return data.map(
                (item) => {
                  item.isLeaf = false
                  item.loading = false
                  delete item.children
                  return item
                }
              )
            }
          },
          success: (res) => resolve(res),
          fail: (err) => reject(err)
        }
      )
    }
  )
}

const options = ref<Option[]>([])

onMounted(
  async () => {
    options.value = await getData()
    if (props.modelValue && props.modelValue.length) {
      const [cities, towns] = await Promise.all(props.modelValue.slice(0, 2).map((item) => getData(item)))
      let province: any
      let city: any
      let town: any
      props.modelValue.forEach(
        (code, level) => {
          switch (level) {
            case 0:
              province = find(options.value, ['code', code])
              break
            case 1:
              city = find(cities, ['code', code])
              if (province) province.children = [city]
              break
            case 2:
              town = find(towns, ['code', code])
              if (province && city) province.children[0].children = [town]
              break
          }
        }
      )
    }
  }
)

const loadData = (selectedOptions: any) => {
  const targetOption = selectedOptions[selectedOptions.length - 1]
  targetOption.loading = true

  useAjax<Option>(
    {
      action: 'GET /common/area/children',
      params: () => ({
        parentCode: selectedOptions[selectedOptions.length - 1].code
      }),
      convert: {
        client (data) {
          return data.map(
            (item) => {
              item.isLeaf = false
              item.loading = false
              delete item.children
              return item
            }
          )
        }
      },
      success (data) {
        targetOption.loading = false
        if (!data.length) {
          targetOption.isLeaf = true
        } else {
          targetOption.children = data
        }
      }
    }
  )
}

const onUpdateValue = (val: string[]) => {
  emit('update:modelValue', val)
  emit('change', val)
}
</script>
