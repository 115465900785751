<template>
  <a-drawer
    title="选择品牌"
    class="ant-drawer__have-footer"
    :attrs="$attrs"
    placement="right"
    :visible="visible"
    width="480"
    @close="close"
  >
    <div>
      <a-input-search placeholder="请输入品牌名称" v-model:value="searchKeyword" @search="search" lazy>
      </a-input-search>
    </div>
    <div>
      <div style="margin: 5px 0">已选择</div>
      <div class="checked-list-box">
        <a-tag v-for="(item, index) in checkedList" :key="item.nameHint + index" closable @close="deleBrand(item)">{{
          item.name
        }}</a-tag>
      </div>
    </div>
    <a-form>
      <div v-for="item of showBrands" :key="item.key">
        <div>{{ item.key }}</div>
        <div class="check-list-box">
          <span class="check-list-box__item" v-for="check in item.list" :key="check.name">
            <a-checkbox v-model:checked="check.checked" @change="checkChange(check)">{{ check.name }} </a-checkbox>
          </span>
        </div>
      </div>
    </a-form>
    <div class="drawer-footer">
      <a-button style="margin-right: 8px" @click="close">取消</a-button>
      <a-button type="primary" @click="confirm">确定</a-button>
    </div>
  </a-drawer>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed, watch, unref, PropType, toRaw } from 'vue'
import { useAjax } from '@vue-mfe/utils'
import { Drawer as ADrawer, Tag as ATag, InputSearch as AInputSearch, Checkbox as ACheckbox } from 'ant-design-vue'
interface Brand {
  logoUrl: string
  name: string
  nameHint: string
  checked?: Boolean
}

export default defineComponent(
  {
    // name: 'drawer-brands',
    props: {
      visible: Boolean as PropType<boolean>,
      brands: {
        type: Array as PropType<Brand[]>,
        require: true,
        defalut: () => []
      }
    },
    components: {
      ADrawer,
      ATag,
      AInputSearch,
      ACheckbox
    },
    emits: ['update:visible', 'update:brands'],
    setup (props, { emit }) {
      const brandsList = useAjax(
        {
          action: 'GET /common/brand',
          convert: {
            client (data) {
              data = data.map(
                (item) => {
                  item.checked = false
                  return item
                }
              )
              return data
            }
          },
          success (data) {
            state.allBrands = data
          },
          lazy: true
        }
      )
      const state = reactive(
        {
          allBrands: [] as any[],
          searchKey: '',
          searchKeyword: '' as string,
          checkedList: [] as any[],
          searchedBrands: [] as any[]
        }
      )
      const showBrands = computed(
        () => {
          const res = [] as any[]
          const data = state.searchedBrands
          const acronym = [...new Set(data.map((item: Brand) => item.nameHint.slice(0, 1)))]
          acronym.forEach(
            (key) => {
              const list = data.filter((item: Brand) => item.nameHint.slice(0, 1) === key)
              res.push({ key, list })
            }
          )
          return res
        }
      )
      const search = (value: any) => {
        const searchKeyword = value
        state.searchedBrands = state.allBrands.filter(
          (inner: Brand) =>
            inner.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            toRaw(inner).nameHint.toLowerCase().includes(searchKeyword.toLowerCase())
        )
      }
      const checkChange = (item: any) => {
        if (item.checked) state.checkedList.push(item)
        else {
          const index = state.checkedList.findIndex((row) => row.name === item.name)
          state.checkedList.splice(index, 1)
        }
      }
      const deleBrand = (item: any) => {
        const index = state.checkedList.findIndex((row) => row.name === item.name)
        const brand = state.searchedBrands.find((row) => row.name === item.name)
        state.checkedList.splice(index, 1)
        brand.checked = false
      }
      brandsList.run()
      watch(
        () => unref(props.visible),
        (value) => {
          if (value) {
            state.searchKeyword = ''
            search('')
            if (props.brands) {
              state.checkedList = props.brands.map(
                (inner) => {
                  const row = state.allBrands.find((item: any) => item.name === inner)
                  row.checked = true
                  return row
                }
              )
            }
          }
        }
      )
      const close = () => {
        emit('update:visible', false)
        state.allBrands.forEach(
          (item: any) => {
            item.checked = false
          }
        )
      }
      const confirm = () => {
        emit('update:visible', false)
        emit(
          'update:brands',
          state.checkedList.map((inner) => inner.name)
        )
      }
      return {
        ...toRefs(state),
        showBrands,
        checkChange,
        deleBrand,
        close,
        confirm,
        search
      }
    }
  }
)
</script>

<style scoped>
.check-list-box {
  display: flex;
  flex-wrap: wrap;
}
.check-list-box__item {
  width: 120px;
}
.checked-list-box {
  display: flex;
  flex-wrap: wrap;
  padding: 4px 24px 24px 0px;
  min-height: 60px;
}
.checked-list-box :deep(.ant-tag) {
  min-width: 70px;
  padding: 8px 12px;
  margin-right: 6px;
  margin-bottom: 12px;
}
</style>
