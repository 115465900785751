<template>
  <a-table v-bind="bindPropsAttrs">
    <template #[item]="data" v-for="item in Object.keys($slots)" :key="item">
      <slot :name="item" v-bind="data"></slot>
    </template>
  </a-table>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { Table as ATable } from 'ant-design-vue'
import type { TableState } from 'ant-design-vue/es/table/interface'
import type { ETablePaging } from '@vue-mfe/utils'

import { isFunction, omit } from 'lodash-es'

type Pagination = TableState['pagination']

export default defineComponent(
  {
    props: {
      ...ATable.props,
      pagination: {
        type: Boolean as PropType<Boolean>
      },
      paging: {
        type: Object as PropType<ETablePaging>
      },
      method: {
        type: Function as PropType<() => void>
      }
    },

    emits: ['register', 'update:paging', 'current-change'],

    setup (props, { attrs, emit, slots }) {
      const bindPropsAttrs = computed(
        () => {
          const custom: any = {
            customRow: (record: any, index: number) => ({
              onClick: () => emit('current-change', record, index)
            })
          }
          if (props.pagination && props.paging) {
            custom.pagination = {
              total: props.paging.itemCount,
              current: props.paging.pageIndex,
              pageSize: props.paging.pageSize
            }
            custom.onChange = (page: Pagination) => {
              emit(
                'update:paging',
                Object.assign(
                  props.paging,
                  {
                    pageIndex: page!.current,
                    pageSize: page!.pageSize
                  }
                )
              )
              props.method && isFunction(props.method) && props.method()
            }
          }
          return omit(
            {
              ...props,
              ...attrs,
              ...custom
            },
            ['method']
          )
        }
      )

      return { bindPropsAttrs, slots }
    }
  }
)
</script>
