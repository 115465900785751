<template>
  <svg aria-hidden="true" :style="style">
    <use :xlink:href="symbolId" />
  </svg>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { PropType, CSSProperties } from 'vue'

const props = defineProps(
  {
    size: {
      type: Number as PropType<number>,
      default: 14
    },
    prefix: {
      type: String as PropType<string>,
      default: 'icon'
    },
    name: {
      type: String as PropType<string>,
      required: true
    }
  }
)

const symbolId = computed(() => `#${props.prefix}-${props.name}`)

const style = computed(
  (): CSSProperties => {
    let s = `${props.size}`
    s = `${s.replace('px', '')}px`
    return { width: s, height: s }
  }
)
</script>

<style></style>
