<template>
  <a-upload v-bind="bindPropsAttrs" @change="onHandleChange" :disabled="loading">
    <template v-if="type === 'upload'">
      <div v-if="modelValue && max && modelValue.length < max">
        <loading-outlined v-if="loading"></loading-outlined>
        <plus-outlined v-else></plus-outlined>
        <div>上传</div>
      </div>
    </template>

    <template v-else>
      <a-button><slot></slot></a-button>
    </template>
  </a-upload>

  <a-modal v-model:visible="previewVisible" :footer="null" @cancel="previewVisible = false">
    <div class="mt-20px">
      <img alt="image" style="width: 100%" :src="previewImage" />
    </div>
  </a-modal>
</template>

<script lang="ts" setup>
import { PropType, ref, computed, watch, useAttrs } from 'vue'
import { Upload as AUpload, Modal as AModal } from 'ant-design-vue'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { omit } from 'lodash-es'

import { useStore } from '@vue-mfe/utils'
import type { OssState } from '@vue-mfe/utils'

interface FileItem {
  uid: string
  name?: string
  status?: string
  response?: string
  percent?: number
  url: string
  preview?: string
  originFileObj?: any
}

const props = defineProps(
  {
    modelValue: {
      type: Array as PropType<any[]>
    },
    dir: {
      type: String as PropType<String>
    },
    max: {
      type: Number as PropType<Number>
    },
    type: {
      type: String as PropType<'upload' | 'button'>,
      default: 'upload'
    },
    action: String as PropType<String>
  }
)

const emit = defineEmits(['update:modelValue', 'change', 'onSuccess'])
const attrs = useAttrs()

const store = useStore()
const loading = ref<boolean>(false)
const previewVisible = ref<boolean>(false)
const previewImage = ref<string>('')

const fileList = ref<FileItem[]>([])

watch(
  () => props.modelValue,
  (value = []) => {
    fileList.value = value.map(
      (item, index) => ({
        uid: `${index}`,
        url: item,
        status: 'done'
      })
    )
  },
  { immediate: true }
)

const bindPropsAttrs = computed(
  () => {
    const custom: any = {}
    custom.fileList = fileList.value
    custom['onUpdate:fileList'] = (files: FileItem[]) => (fileList.value = files)
    if (props.type === 'upload') {
      custom.action = store.state.ossData.host
      custom['list-type'] = 'picture-card'
      custom.data = store.state.ossData
      custom.beforeUpload = beforeUpload
      custom.onPreview = onPreview
    } else if (props.type === 'button') {
      custom.action = props.action && props.action.startsWith('/enoquote') ? props.action : '/enoquote' + props.action
      custom.showUploadList = false
    }
    return omit(
      {
        ...attrs,
        ...custom
      },
      ['update:modelValue']
    )
  }
)

function beforeUpload (file: File) {
  const ossData: OssState = Object.assign({}, store.state.oss)
  ossData.OSSAccessKeyId = ossData.accessId
  ossData.Signature = ossData.signature
  ossData.key = ossData.dir + (props.dir ? `/${props.dir}/` : '/') + file.name
  store.actions.changeState({ ossData })
  return Promise.resolve(file)
}

function onPreview (file: any) {
  previewImage.value = file.url
  previewVisible.value = true
}

const onHandleChange = (info: any) => {
  const { file } = info
  if (file.status === 'uploading') {
    loading.value = true
    return
  }
  if (file.status === 'done') {
    loading.value = false
    if (props.type === 'upload') {
      const modelValueCopy = props.modelValue ? props.modelValue.map((file) => file) : []
      modelValueCopy?.push(`${store.state.ossData.host}/${store.state.ossData.key}`)
      emit('update:modelValue', modelValueCopy)
      emit('change', modelValueCopy)
    } else if (props.type === 'button') {
      emit('change', info.file.response)
    }
    return
  }
  if (file.status === 'error') {
    loading.value = false
  }

  if (file.status === 'removed') {
    if (file.url) {
      const modelValueCopy = props.modelValue ? props.modelValue.map((file) => file) : []
      const index = modelValueCopy.findIndex((item) => item === file.url)
      if (~index) {
        modelValueCopy.splice(index, 1)
        emit('update:modelValue', modelValueCopy)
      }
    } else {
      const fileListCopy = fileList.value.map((file) => file)
      const index = fileList.value.findIndex((item) => item.uid === file.uid)
      if (~index) {
        fileListCopy.splice(index, 1)
        emit('update:modelValue', fileListCopy)
      }
    }
  }
}
</script>
