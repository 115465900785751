<template>
  <a-input :value="modelValue" @update:value="change" v-bind="attrs" style="text-align: center">
    <template #prefix>
      <MinusOutlined @click.stop="minus" />
    </template>
    <template #suffix>
      <PlusOutlined @click.stop="plus" />
    </template>
  </a-input>
</template>

<script lang="ts">
import { defineComponent, PropType, toRefs } from 'vue'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons-vue'

export default defineComponent(
  {
    components: { PlusOutlined, MinusOutlined },
    props: {
      modelValue: {
        type: Number as PropType<number>
      },
      max: {
        type: Number as PropType<number>,
        default: Infinity
      },
      min: {
        type: Number as PropType<number>,
        default: 0
      },
      step: {
        type: Number as PropType<number>,
        default: 1
      }
    },
    emits: ['update:modelValue'],

    setup (props, { attrs, emit }) {
      const { max, min } = toRefs(props)
      const change = (value: any) => {
        let newValue = Number(
          value
            .toString()
            .replace(/[^\d^.]+/g, '')
            .replace('.', '$#$')
            .replace(/\./g, '')
            .replace('$#$', '.')
        )
        if (newValue < min.value) newValue = min.value
        if (newValue > max.value) newValue = max.value
        emit('update:modelValue', newValue)
      }
      const plus = () => (props.modelValue || props.modelValue === 0) && change(props.modelValue + props.step)
      const minus = () => (props.modelValue || props.modelValue === 0) && change(props.modelValue - props.step)
      return {
        attrs,
        // modelValue,
        change,
        minus,
        plus
      }
    }
  }
)
</script>
