<template>
  <a-form v-bind="attrs" class="myform">
    <slot></slot>
  </a-form>
</template>

<script lang="ts" setup>
import { useAttrs, watch } from 'vue'
import { debounce } from 'lodash-es'
const emit = defineEmits(['search'])
const attrs = useAttrs()
watch(
  () => attrs.model,
  debounce(() => emit('search'), 800),
  {
    deep: true
  }
)
</script>
