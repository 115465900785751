<template>
  <component :is="datePickerComponent" v-bind="bindPropsAttrs" @change="onChange">
    <template #suffixIcon>
      <CalendarOutlined />
    </template>
  </component>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { CalendarOutlined } from '@ant-design/icons-vue'
import moment from 'moment'

import { DatePicker } from 'ant-design-vue'

import { omit } from 'lodash-es'

export default defineComponent(
  {
    components: { CalendarOutlined },
    props: {
      modelValue: {
        type: String as PropType<moment.Moment | string>,
        default: 'date'
      },
      type: {
        type: String as PropType<string>,
        default: 'date'
      },
      start: {
        type: String as PropType<moment.Moment | string>,
        default: ''
      },
      end: {
        type: String as PropType<moment.Moment | string>,
        default: ''
      }
    },

    emits: ['update:modelValue', 'update:start', 'update:end'],

    setup (props, { emit, attrs }) {
      const datePickerComponent = computed(
        () => {
          switch (props.type) {
            case 'month':
              return DatePicker.MonthPicker
            case 'range':
              return DatePicker.RangePicker
            default:
              return DatePicker
          }
        }
      )

      const modelValue = computed(
        {
          get: (): any => (props.type === 'range' ? [props.start, props.end] : props.modelValue),
          set: (value) => {
            if (props.type === 'range') {
              emit('update:start', value[0] || '')
              emit('update:end', value[1] || '')
            } else {
              emit('update:modelValue', value || '')
            }
          }
        }
      )

      const bindPropsAttrs = computed(
        () => {
          const custom: any = {}
          custom.format = 'YYYY-MM-DD'
          custom.valueFormat = 'YYYY-MM-DD'
          custom.value = modelValue.value
          if (props.type === 'month') {
            custom.format = 'YYYY-MM'
            custom.valueFormat = 'YYYY-MM'
          }
          return omit(
            {
              ...attrs,
              ...custom
            },
            ['update:modelValue']
          )
        }
      )

      const onChange = (value: any) => (modelValue.value = value)

      return { datePickerComponent, modelValue, bindPropsAttrs, onChange }
    }
  }
)
</script>

<style></style>
